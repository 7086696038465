import React from 'react'

const SystemDefinitionOutput = ({ survey, product, stack }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  // const practiceAndProductSummaryOutput = summary?.practiceAndProductSummaryOutput

  return (
    <>
      <div>
        {product?.dcb0129OrDPIAProductDescription?.split('\n')?.map((block, index) => (
          <p key={index} className="whitespace-pre-line">
            {block.trim()}
          </p>
        ))}
      </div>
      <br />
    </>
  )
}

export default SystemDefinitionOutput
