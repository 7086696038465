import React from 'react'

const SurveyQuestionsOutput = ({ product, surveyQuestions }) => {
  const questionTypeDescriptions = {
    rating: 'Select a rating (Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree)',
    'open-ended': 'Free-text comment',
  }

  return (
    <>
      <h3>Survey questions</h3>
      {surveyQuestions.map(question => (
        <>
          <h4>{question.question.replace('<<softwareName>>', product.name)}</h4>
          <p>Type: {questionTypeDescriptions[question.type]}</p>
          <br />
        </>
      ))}
    </>
  )
}

export default SurveyQuestionsOutput
