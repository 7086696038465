import React from 'react'
import CountUp from 'react-countup'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import ProductImage from '../../ProductImage'

const IcsProductItem = ({ icbId, surveyCount, product }) => {
  return (
    <Link to={`/clinical-safety/${icbId}/product/${product.id}`}>
      <div className="bg-white h-full p-6 sm:p-8 sm:py-14 rounded overflow-hidden group cursor-pointer">
        <div className="w-full flex flex-col sm:flex-row gap-4 md:gap-8 justify-between">
          <div className="flex gap-x-4">
            <div className="flex-shrink-0">
              <ProductImage softwareProduct={product} height="h-14" width="w-14" />
            </div>
            <div>
              <div className="group-hover:underline underline-offset-2 decoration-2 text-xl font-semibold mt-3">
                {product.name}
              </div>
              <div className="text-sm text-slate-700 mt-3 line-clamp-2">{product.description}</div>
              <div className="flex gap-2 items-center text-base flex-shrink-0 mt-4">
                <span className="group-hover:underline underline-offset-2 decoration-2 text-center">
                  View
                </span>
                <ArrowRightIcon className="h-4 w-4" />
              </div>
            </div>
          </div>

          {/* <div className="flex-shrink-0 mt-2">
            <dl>
              <div className="flex flex-col-reverse">
                <dt className="text-base text-slate-800 leading-5">
                  {surveyCount > 1 ? 'Surveys' : 'Survey'}
                </dt>
                <dd className="text-3xl font-semibold tracking-tight text-indigo-500">
                  <CountUp end={surveyCount} duration={3} />
                </dd>
              </div>
            </dl>
          </div> */}
        </div>
      </div>
    </Link>
  )
}

export default IcsProductItem
