import React from 'react'
import HtmlDisplayBox from '../../../HTMLDisplayBox'
import SurveySummaryOutput from './SurveySummaryOutput'
import SurveyResultsOutput from './SurveyResultsOutput'
import SurveyCommentsOutput from './SurveyCommentsOutput'
import SurveyHazardAnalysisOutput from './SurveyHazardAnalysisOutput'
import SurveyHazardLogOutput from './SurveyHazardLogOutput'
import IntroductionOutput from './IntroductionOutput'
import SystemDefinitionOutput from './SystemDefinitionOutput.js'
import ClinicalRiskManagementSystemOutput from './ClinicalRiskManagementSystemOutput'
import ClinicalRiskEvaluationOutput from './ClinicalRiskEvaluationOutput.js'
import SummarySafetyStatement from './SummarySafetyStatement.js'
import ExecutiveSummaryHazardLogRisks from './ExecutiveSummaryHazardLogRisks.js'
import ExecutiveSummaryTransferredRisks from './ExecutiveSummaryTransferredRisks.js'
import { generateClinicalSafetyDocx } from '../../../../utilities/documents.js'
import CRMP from './CRMP.js'
import CRMS from './CRMS.js'
import SurveyQuestionsOutput from './SurveyQuestionsOutput.js'

const AdminOutputsModal = ({ product, survey, orgStats, surveyQuestions, stack, setOpen }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  // Needs adjustment according OpenAi's pricing
  const gpt4oInputCostPerToken = 2.5 / 1000000
  const gpt4oOutputCostCostPerToken = 10 / 1000000

  const promptCost = summary?.hazardLogOutput?.aiTokenCounts?.promptTokens * gpt4oInputCostPerToken
  const completionCost =
    summary?.hazardLogOutput?.aiTokenCounts?.completionTokens * gpt4oOutputCostCostPerToken

  return (
    <>
      <div>
        <h2 className="text-xl sm:text-4xl font-bold">Admin outputs</h2>
        {summary?.updatedAt && (
          <div className="mt-1">
            Updated at: {new Date(summary.updatedAt).toLocaleString('en-GB')}
          </div>
        )}

        {summary?.timeTaken && (
          <div className="mt-1">Time Taken: {Math.round(summary.timeTaken / 100) / 10} seconds</div>
        )}

        <button
          onClick={() =>
            generateClinicalSafetyDocx({
              practice: stack.name,
              product: product.name,
              filename: '',
              documentReference: '',
              date: new Date(survey?.closedDate).toLocaleDateString(),

              executiveSummary: document.getElementById('executiveSummary').innerHTML,
              introduction: document.getElementById('introduction').innerHTML,
              systemDefinition: document.getElementById('systemDefinition').innerHTML,
              crms: document.getElementById('crms').innerHTML,
              clinicalRiskAnalysis: document.getElementById('clinicalRiskAnalysis').innerHTML,
              clinicalRiskEvaluation: document.getElementById('clinicalRiskEvaluation').innerHTML,
              summarySafetyStatement: document.getElementById('summarySafetyStatement').innerHTML,
              appendixA: document.getElementById('appendixA').innerHTML,
              appendixB: document.getElementById('appendixB').innerHTML,
              appendixC: document.getElementById('appendixC').innerHTML,
              appendixD: document.getElementById('appendixD').innerHTML,
              appendixE: document.getElementById('appendixE').innerHTML,
            })
          }
        >
          Download
        </button>

        {!!promptCost && !!completionCost && (
          <div className="mt-1">
            Prompt cost: £{Math.round(promptCost * 100) / 100}, Completion cost: £
            {Math.round(completionCost * 100) / 100}, Total: £
            {Math.round((promptCost + completionCost) * 100) / 100}
          </div>
        )}

        <div className="mt-4">
          <h3 className="text-xl font-semibold">
            Survey summary, results & clinical safety analysis
          </h3>
          <div className="mt-2" id="copy-html">
            <HtmlDisplayBox
              content={
                <>
                  <div id="executiveSummary">
                    <ExecutiveSummaryTransferredRisks
                      summary={summary}
                      stack={stack}
                      product={product}
                    />

                    <ExecutiveSummaryHazardLogRisks summary={summary} />
                  </div>

                  <div id="introduction">
                    <IntroductionOutput
                      product={product}
                      survey={survey}
                      stack={stack}
                      orgStats={orgStats}
                    />
                  </div>

                  <div id="systemDefinition">
                    <SystemDefinitionOutput product={product} survey={survey} stack={stack} />
                  </div>

                  <div id="crms">
                    <ClinicalRiskManagementSystemOutput stack={stack} />
                  </div>

                  <div id="clinicalRiskAnalysis">
                    <SurveySummaryOutput
                      product={product}
                      survey={survey}
                      stack={stack}
                      surveyQuestions={surveyQuestions}
                    />
                  </div>

                  <div id="clinicalRiskEvaluation">
                    <ClinicalRiskEvaluationOutput
                      summary={summary}
                      stack={stack}
                      product={product}
                    />
                  </div>

                  <div id="summarySafetyStatement">
                    <SummarySafetyStatement stack={stack} product={product} />
                  </div>

                  <div id="appendixA">
                    <SurveyQuestionsOutput product={product} surveyQuestions={surveyQuestions} />
                  </div>

                  <div id="appendixB">
                    <SurveyCommentsOutput
                      product={product}
                      survey={survey}
                      surveyQuestions={surveyQuestions}
                    />
                  </div>

                  <div id="appendixC">
                    <SurveyHazardAnalysisOutput survey={survey} />
                  </div>

                  <div id="appendixD">
                    <CRMS />
                  </div>

                  <div id="appendixE">
                    <CRMP stack={stack} product={product} />
                  </div>

                  <SurveyResultsOutput
                    product={product}
                    survey={survey}
                    surveyQuestions={surveyQuestions}
                  />
                </>
              }
            />
          </div>
        </div>

        <div className="mt-12">
          <h3 className="text-xl font-semibold">Hazard log</h3>
          <div className="mt-2">
            <HtmlDisplayBox content={<SurveyHazardLogOutput survey={survey} />} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminOutputsModal
