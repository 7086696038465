import React from 'react'

const ClinicalRiskManagementSystemOutput = ({ stack }) => {
  return (
    <>
      <div>
        <p>
          The Clinical Risk Management System (Appendix D) has been applied throughout all phases of
          the product implementation.
        </p>
        <p>
          In addition to the application of the Clinical Risk Management System, this Clinical
          Safety Case Report has been developed in accordance with the requirements of DCB0160,
          which will be followed throughout the deployment process to ensure compliance and
          effective risk mitigation.
        </p>
        <p>
          The {stack.name} has adopted Bordercross Health as its designated Clinical Risk Management
          System to systematically identify, assess, and manage clinical risks throughout the entire
          technology lifecycle. This includes pre-implementation risk evaluation, ongoing
          post-deployment monitoring, and structured decommissioning procedures.
        </p>
      </div>
    </>
  )
}

export default ClinicalRiskManagementSystemOutput
