import React from 'react'
import { classNames } from '../utilities/general'
import { formatICBName } from '../utilities/ods'

const IcbSelector = ({ defaultValue, onChange, icbs, hideLabel }) => {
  return (
    <div>
      <label htmlFor="tabs" className={classNames(hideLabel && 'sr-only', 'font-semibold')}>
        Select an ICS
      </label>
      <select
        id="tabs"
        name="tabs"
        className="block mt-2 w-full h-12 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        defaultValue={defaultValue}
        onChange={e => onChange(e.target.value)}
      >
        {icbs?.map(icb => {
          const icbName = formatICBName(icb.name)

          return (
            <option key={icb.id} value={icb.id}>
              {icbName}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default IcbSelector
