import { useState, useEffect } from 'react'
import { get } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

const useIcbs = () => {
  const [icbs, setIcbs] = useState([])
  const [loadingIcbs, setLoadingIcbs] = useState(true)

  useEffect(() => {
    const getIcbs = async () => {
      setLoadingIcbs(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/stacks/icbs/list`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const icbs = await response.body.json()
      setIcbs(icbs)
      setLoadingIcbs(false)
    }

    getIcbs()
  }, [])

  return { icbs, loadingIcbs }
}

export default useIcbs
