import React from 'react'
import { conditionalTableRowFormatting } from '../../../../utilities/general'

const ClinicalRiskEvaluationOutput = ({ summary, stack, product }) => {
  const transferredRisksOutput = summary?.transferredRisksExecutiveSummaryOutput
  const tableCellStyles = {
    border: '1px solid #cbd5e1',
    padding: '5px',
  }

  return (
    <>
      <p>
        The DCB0129 hazard log highlights risks that deploying organisations, including {stack.name}
        , should be aware of. The table below summarises hazards identified in the DCB0129 for{' '}
        {product.name}.
      </p>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableCellStyles}>Hazard Description</th>
            <th style={tableCellStyles}>Potential Impact</th>
            <th style={tableCellStyles}>Controls</th>
            <th style={tableCellStyles}>Initial Risk</th>
          </tr>
        </thead>
        <tbody>
          {transferredRisksOutput?.map((transferredRisk, index) => {
            return (
              <tr key={index} style={{ background: conditionalTableRowFormatting(index) }}>
                <td style={tableCellStyles}>{transferredRisk?.hazardDescription}</td>
                <td style={tableCellStyles}>{transferredRisk?.hazardPotentialImpact}</td>
                <td style={tableCellStyles}>{transferredRisk?.hazardControls}</td>
                <td style={tableCellStyles}>{transferredRisk?.hazardInitialRisk}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <h3>DCB0160 Hazard Log</h3>
      <p>
        The hazard log is documented in the accompanying Excel file and serves as a systematic
        record of identified hazards associated with the implementation of OneResults and
        OneMonitoring. It provides a detailed assessment of risks, their contributing factors, and
        the controls required to mitigate them. A summary of the additional identified hazards is
        included in the executive summary.
      </p>
      <p>The hazard log captures the following key elements:</p>
      <ul>
        <li>Hazard Assessment</li>
        <li>Initial Risk</li>
        <li>Residual Risk</li>
      </ul>
      <p>
        The deploying organisation reviewed the accompanying hazard log to ensure it accurately
        reflected the organisation's issues and subsequently completed the risk scoring process.
      </p>
      <p>Full details can be seen in the hazard log excel file.</p>
    </>
  )
}

export default ClinicalRiskEvaluationOutput
