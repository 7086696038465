import React from 'react'

const CRMS = () => {
  return (
    <>
      <h3>Clinical Risk Management System</h3>

      <h4>Introduction</h4>
      <p>
        This Clinical Risk Management System (CRMS) outlines the processes to be followed to ensure
        that all healthcare IT used to support care within the Organisation is developed,
        implemented and used in as safe manner.
      </p>
      <p>
        This CRMS provides a framework that promotes the effective risk management, by the
        Organisation, of potential health IT hazards and operational incidents.
      </p>
      <p>
        This CRMS compliments existing risk management processes that should be defined in the
        Organisation's Risk Management Strategy and wherever practical, uses existing procedures,
        processes and governance arrangements.
      </p>
      <p>
        This CRMS addresses the requirements of DCB0129 and DCB0160 and follows best practice as
        promoted by NHS Digital.
      </p>
      <p>This CRMS will be reviewed and maintained in accordance with the Organisation's policy.</p>

      <h4>Purpose</h4>
      <p>
        The aim of the CRMS is to ensure that all of the Organisational staff involved with the
        development, implementation and use of healthcare IT systems are aware of the activities
        that are required to be undertaken to ensure patient safety is improved rather than
        compromised from the introduction of healthcare IT systems.
      </p>
      <p>
        The Organisation is required to adhere to National Information standards created and
        monitored via the Data Coordination Board (DCB) within NHS Information Standards frameworks.
      </p>
      <p>
        The mechanisms used are approved process Clinical Risk Management System compliance
        documents.
      </p>
      <p>This Clinical Risk Management System will be reviewed periodically to ensure that:</p>
      <ul>
        <li>changes in working practices are incorporated</li>
        <li>issues identified though an established internal audit programme are addressed</li>
        <li>
          the safety approach continues to adhere to the requirements of applicable international
          standards
        </li>
        <li>
          the system continues to protect the safety of patients in a complex and changing
          environment.
        </li>
      </ul>

      <h4>Audience</h4>
      <p>
        This document is for the Organisational staff that are involved in ensuring the safety of
        healthcare IT systems, products or services.
      </p>

      <h4>Scope</h4>
      <p>
        This applies to the Organisation and to all subsequent updates or upgrades to systems. The
        policy also applies to any local customisations or specific configurations made to a
        healthcare IT system by the Organisation.
      </p>
      <p>
        If clarification is required of whether any system falls within scope of this CRMS this
        should be raised with the nominated Clinical Safety Officer (CSO) for clarification. This
        nominated person provides clinical and organisational leadership on healthcare IT Patient
        Safety on behalf of the Organisation.
      </p>

      <h4>Definitions</h4>
      <p>Note - Also see the Organisation’s Risk Management Strategy</p>
      <p>
        <strong>CSO</strong>: Clinical Safety Officer - the person responsible for ensuring that the
        healthcare IT Clinical Risk Management System is applied to all clinical systems. The
        Clinical Safety Officer (CSO) for the Organisation is responsible for ensuring the safety of
        a healthcare IT system through the application of clinical risk management. The Clinical
        Safety Officer must hold a current registration with an appropriate professional body
        relevant to their training and experience. They also need to be suitably trained and
        qualified in risk management or have an understanding in principles of risk and safety as
        applied to healthcare IT systems. The Clinical Safety Officer ensures that the processes
        defined by the clinical risk management system are followed.
      </p>
      <p>
        <strong>DCB</strong>: Data Coordination Board
      </p>

      <h4>Healthcare IT Clinical Risk Management Deliverables</h4>
      <h5>Clinical Risk Management File</h5>
      <p>
        The Organisation will establish a Clinical Risk Management File (CRMF) for each safety
        related healthcare IT system. The purpose of the CRMF is to provide a central repository
        where all safety related information pertaining to the healthcare IT system is stored and
        controlled.
      </p>
      <h5>Clinical Risk Management Plan</h5>
      <p>
        The Organisation will establish a Clinical Risk Management Plan (CRMP) for each safety
        related healthcare IT system. The purpose of the CRMP is to identify the clinical risk
        management activities that are to be undertaken and the phasing of these activities in the
        project lifecycle. The CRMP will also identify the resources required to discharge these
        clinical risk management activities.
      </p>
      <h5>Hazard Log</h5>
      <p>
        The Organisation will establish and maintain a Hazard Log (HL) for each safety related
        healthcare IT system. The HL will be controlled and configured in accordance with the
        Organisation document control / quality management policy.
      </p>
      <p>
        The HL will be made available within the CRMF. The purpose of the HL is to manage the
        effective resolution and communication of hazard risk within The Organisation.
      </p>
      <h5>Clinical Safety Case</h5>
      <p>
        The Organisation will establish and develop a Clinical Safety Case (CSC) for each safety
        related HIT system.
      </p>
      <h5>Clinical Safety Case Report</h5>
      <p>
        The Organisation will issue a Clinical Safety Case Report (CSCR) for each safety related
        healthcare IT system. The CSCR will be issued to support initial deployment and will be
        updated during the lifecycle of the healthcare IT system should the safety characteristics
        change. The CSCR will be controlled and configured in accordance with the Organisation's
        document control policy. The HL will be made available within the CRMF.
      </p>

      <h4>Healthcare IT Clinical Risk Management Activities</h4>
      <h5>Hazard Identification</h5>
      <p>
        The Organisation will conduct hazard identification workshops to identify potential hazards
        associated with the deployment and use of a healthcare IT system. The CSO will be
        responsible for facilitating such workshops and ensuring attendance from appropriate
        representatives.
      </p>
      <p>
        The workshops will have minutes taken and a copy stored in the CRMF. If a healthcare IT
        solution is deemed not to be safety related then this decision will be formally recorded.
      </p>
      <p>
        Where any third-party components are used to support the healthcare IT system then they will
        be considered in the scope of the hazard identification activities and subsequent risk
        assessment. Where none are used a positive declaration to this effect will be recorded in
        the minutes.
      </p>
      <p>All identified hazards will be recorded in the HL.</p>

      <h5>Risk Assessment</h5>
      <p>
        The Organisation will conduct healthcare IT system risk assessment in accordance with the
        Risk Management Strategy
      </p>
      <p>The HL will be updated to capture the risk assessment.</p>

      <h5>Risk Evaluation</h5>
      <p>
        The Organisation will conduct healthcare IT system risk evaluation in accordance with the
        Risk Management Strategy
      </p>
      <p>The HL will be updated to capture the risk evaluation.</p>

      <h5>Risk Control</h5>
      <p>
        Where the initial risk evaluation is deemed unacceptable, further risk controls will be
        required. The Organisation will manage healthcare IT system risk in accordance with the Risk
        Management Strategy
      </p>
      <p>
        Details of the risk control measure and evidence of effective implementation will be
        captured in the HL.
      </p>

      <h5>Incident Management</h5>
      <p>
        Clinical Risk Management activities within the Organisation and the healthcare IT programmes
        and services offered are completed within the corporate risk management strategy. As such
        clinical safety related incidents are dealt with in a similar manner as other incident
        within the organisational such as financial, reputational, technical and other service
        impacting categories.
      </p>

      <h4>Clinical Safety Competence and Training</h4>
      <h5>Overview</h5>
      <p>
        The clinical safety activities described in this Clinical Risk Management System shall be
        undertaken by competent staff. Suitable training shall be undertaken by staff to maintain
        and expand their level of competence.
      </p>
      <h5>Competency</h5>
      <p>
        All of the staff identified in the organisation chart, shall be sufficiently competent for
        the roles and task which they are asked to undertake. Where an individual does not have
        sufficient experience or knowledge then that person shall be monitored, and his/her work
        reviewed, by someone who has the necessary competence. Such supervision shall prevail until
        it is judged that the individual has amassed the necessary experience to undertake such
        tasks unsupervised.
      </p>
      <p>
        In assessing competency, the different functional roles required to fully discharge the
        obligations of the Clinical Risk Management System, and the necessary skills and knowledge
        needed for each, shall be considered. Primary functional roles may include:
      </p>
      <ul>
        <li>
          Conducting discrete safety analyses (for example, a HAZOP or FFA) or defining the Hazard
          Risk Indicators for a particular project.
        </li>
        <li>
          Making a valid judgement on the safety tasks, activities and techniques required for a
          given Health Software Product in order to justify the comprehensiveness and completeness
          of the safety assessment and produce the safety argument with supporting evidence.
        </li>
        <li>
          Assurance of safety assessments and healthcare IT software products. Performance of safety
          techniques and development of the safety argument for a particular healthcare IT software
          product must be independent to any assurance activities for the same.
        </li>
        <li>
          Improving and refining the overall Clinical Risk Management System, for example, audit,
          process change, quality.
        </li>
        <li>
          Ownership and leadership, for example, ultimate safety accountability, culture change,
          influencing and strategic direction.
        </li>
      </ul>
      <p>
        The first test in establishing competency shall be at the interview stage where potential
        staff shall be assessed against the above representative roles and agreed job descriptions.
        Thereafter, competence shall be monitored through the organisation's established appraisal
        scheme. Any perceived deficiencies identified during the course of the work or at the
        appraised stage, especially during probation, shall be addressed immediately, for example,
        through the assignment of a competent supervisor or the provision of suitable training.
      </p>
      <p>
        All registered clinicians involved in safety roles shall, as a minimum, have completed an
        accredited training course.
      </p>
      <h5>Training</h5>
      <p>
        As part of the employment process and thereafter through the appraisal scheme, clinical
        safety personnel will undergo suitable training to develop, maintain or enhance their
        competency level. Such training can comprise:
      </p>
      <ul>
        <li>'on the job' training conducted under supervision</li>
        <li>Internal training courses</li>
        <li>Approved external training courses.</li>
      </ul>
      <p>
        All registered clinicians involved in clinical safety roles shall, as a minimum, have
        completed an accredited training course.
      </p>
      <p>
        Completion of any safety training shall be recorded by the individual on the annual
        appraisal form.
      </p>

      <h4>Audits</h4>
      <h5>Overview</h5>
      <p>
        Audits shall be undertaken to ensure that projects are adhering to the defined safety
        requirements. Such audits will focus on the Clinical Safety Team and third-party suppliers.
      </p>
      <h5>Internal Safety Audits</h5>
      <p>
        The Organisation shall undertake regular internal safety audits to ensure that projects
        undertaken within the organisation are compliant with this Clinical Risk Management System.
        These audits shall be conducted and recorded in accordance with the internal quality
        management procedure.
      </p>
      <p>
        The scope of an internal safety audit will be the formal Clinical Risk Management System and
        the Organisation's documentation supporting this document.
      </p>
      <h5>Supplier Audits</h5>
      <p>
        The Organisation shall undertake regular third-party supplier audits, as a minimum annually,
        to ensure compliance with their Clinical Risk Management System. The audit shall focus on
        the Clinical Risk Management System, the evidence which demonstrates its effective operation
        and any issues arising from the deployment of the healthcare IT products and services. The
        basis for the audit shall be DCB0129.
      </p>
      <p>
        Supplier audits shall be conducted in accordance with the External Safety Audit Procedure.
      </p>
      {/* Governance Arrangements, and Deployment and Ongoing Maintenance in CRMS left out*/}
    </>
  )
}

export default CRMS
