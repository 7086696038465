import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import Button from '../../Button'
import { ArrowLeftIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline'
import useProducts from '../../../hooks/useProducts'
import useIcbs from '../../../hooks/useIcbs'
import { formatICBName } from '../../../utilities/ods'
import SummaryTable from './SummaryTable'

const ClinicalSafetyIcsProductPage = ({ cognitoUser, stack }) => {
  const [icbProducts, setIcbProducts] = useState()
  const [loadingIcbProducts, setLoadingIcbProducts] = useState(false)
  const [icbProductStats, setIcbProductStats] = useState([])
  const [icbLoadingProductStats, setLoadingIcbProductStats] = useState(false)
  const { products, loadingProducts } = useProducts()
  const { icbs, loadingIcbs } = useIcbs()
  const { productId, icbId } = useParams()

  useEffect(() => {
    const getIcbProducts = async () => {
      setLoadingIcbProducts(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/productSurvey/list/icbProducts/${icbId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const products = await response.body.json()
      setIcbProducts(products)
      setLoadingIcbProducts(false)
    }

    if (icbId && productId) getIcbProducts()
  }, [icbId])

  useEffect(() => {
    const getIcbProductStats = async () => {
      setLoadingIcbProductStats(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/productSurvey/icbStats/${icbId}/product/${productId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const products = await response.body.json()
      setIcbProductStats(products)
      setLoadingIcbProductStats(false)
    }

    if (icbId && productId) getIcbProductStats()
  }, [icbId, productId])

  const loading = loadingIcbs || loadingProducts || loadingIcbProducts || icbLoadingProductStats

  const product = products.find(product => product.id === productId)
  const icb = icbs?.find(icb => icb.id === icbId)

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8 bg-slate-50">
      <div className="flex flex-col md:flex-row gap-4 justify-between align-start mt-10">
        <div className="flex-shrink-0">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-center">
            <div>
              <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                {product?.name}
              </h1>
              {icb?.name && (
                <div className="flex gap-2 items-center mt-2">
                  <BuildingOfficeIcon className="h-6 w-6 text-slate-500 stroke-1" />
                  <p className="text-xl text-slate-500">{formatICBName(icb.name)}</p>
                </div>
              )}
            </div>

            {/* <Button
              variant="solid"
              color="indigo"
              to={`/employee-insights/product-survey-setup?stackId=${stackId}`}
            >
              Create new survey
            </Button> */}
          </div>
        </div>
      </div>

      {loading ? (
        <LoadBox />
      ) : (
        <div className="mt-8">
          <SummaryTable icbProductStats={icbProductStats} />
        </div>
      )}
    </div>
  )
}

export default ClinicalSafetyIcsProductPage
