import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { getAddress } from '../../../utilities/ods'
import { formatSurveyType } from '../../../utilities/surveys'
import SurveyTypeBadge from './SurveyTypeBadge'
import { Link } from 'react-router-dom'

const SummaryTableRow = ({ stack }) => {
  return (
    <tr className="border-t border-gray-200" data-height="30">
      <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold" data-f-bold>
        <div className="flex gap-2 items-center">
          <BuildingOfficeIcon className="h-7 w-7 text-gray-500" />
          <div>
            {stack.stackName}
            <div className="font-medium text-xs text-gray-600">
              {getAddress(stack.organisation)}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 space-y-2 text-sm" data-f-bold>
        {stack.surveys.map(survey => {
          return (
            <div>
              <SurveyTypeBadge type={survey.subType} />
            </div>
          )
        })}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-gray-500 text-sm space-y-4" data-f-bold>
        {stack.surveys.map(survey => {
          const startDate = new Date(survey?.createdAt)
          const endDate = new Date(survey?.closedDate)
          const dateFormat = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          }
          return (
            <div>
              {startDate?.toLocaleDateString('en-GB', dateFormat)} -{' '}
              {endDate?.toLocaleDateString('en-GB', dateFormat)}
            </div>
          )
        })}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm space-y-4" data-f-bold>
        {stack.surveys.map(survey => (
          <div>{survey.completions}</div>
        ))}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm space-y-4" data-f-bold>
        {stack.surveys.map(survey => (
          <div>-</div>
        ))}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm space-y-4">
        {stack.surveys.map(survey => (
          <div className="text-indigo-700 hover:underline underline-offset-2">
            <Link to={`/employee-insights/product-survey/${survey.uniqueCode}`}>View</Link>
          </div>
        ))}
      </td>
    </tr>
  )
}

export default SummaryTableRow
