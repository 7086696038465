export const calculateContractWarningLevel = contractEndDate => {
  const currentDate = new Date()
  let oneMonthInFuture = new Date()
  oneMonthInFuture.setMonth(currentDate.getMonth() + 1)
  let threeMonthsInFuture = new Date()
  threeMonthsInFuture.setMonth(currentDate.getMonth() + 3)
  if (contractEndDate < currentDate)
    return {
      label: 'Expired',
      backgroundColour: 'bg-red-100',
      textColour: 'text-red-700',
      ring: 'ring-red-600/20',
    }
  if (contractEndDate <= oneMonthInFuture)
    return {
      label: 'Expiring imminently',
      backgroundColour: 'bg-orange-100',
      textColour: 'text-orange-700',
      ring: 'ring-orange-600/20',
    }
  if (contractEndDate <= threeMonthsInFuture)
    return {
      label: 'Expiring soon',
      backgroundColour: 'bg-yellow-100',
      textColour: 'text-yellow-700',
      ring: 'ring-yellow-600/20',
    }
  return {
    label: '',
    backgroundColour: 'bg-green-100',
    textColour: 'text-green-700',
    ring: 'ring-green-600/20',
  }
}

export const calculateStackCost = (
  stack,
  stackManagementDetails,
  perPatientPricing = false,
  patientCount,
  internalOnly = false
) => {
  let totalCost = 0
  if (!stackManagementDetails?.length) return totalCost

  stackManagementDetails.forEach(stackManagementDetail => {
    if (internalOnly && !stackManagementDetail.procuredInternally) return

    const noPriceSet =
      typeof stackManagementDetail.price === undefined ||
      typeof stackManagementDetail.price === 'object'
    if (!stackManagementDetail.pricingPlan || noPriceSet) return

    let productCost
    if (stackManagementDetail.pricingPlan === 'YEARLY') {
      productCost = stackManagementDetail.price
    } else if (stackManagementDetail.pricingPlan === 'MONTHLY') {
      productCost = stackManagementDetail.price * 12
    } else if (stackManagementDetail.pricingPlan === 'YEARLY_PER_PATIENT') {
      productCost = stackManagementDetail.price * patientCount
    }
    if (perPatientPricing) productCost = productCost / patientCount

    totalCost += productCost
  })

  return totalCost
}

export const calculateProductsRequiringPricingDetails = (stack, stackManagementDetails) => {
  const totalProducts = stack.SoftwareProducts.items.length
  let productsRequiringPricingDetails = totalProducts - stackManagementDetails.length

  stackManagementDetails.forEach(stackManagementDetail => {
    const noPriceSet =
      typeof stackManagementDetail.price === undefined ||
      typeof stackManagementDetail.price === 'object'
    if (!stackManagementDetail.pricingPlan || noPriceSet) return productsRequiringPricingDetails++
  })

  return productsRequiringPricingDetails
}

// These are newer, more tidier cost functions than above
export const getStackCost = stack => {
  let cost = 0
  const patientCount = getPatientCount(stack)

  stack?.StackProductManagements?.items?.forEach(productManagement => {
    const annualCost = calculatePlanCost(
      productManagement.price,
      productManagement.pricingPlan,
      patientCount
    )
    cost += annualCost
  })
  return cost
}
export const calculatePlanCost = (price, plan, patientCount) => {
  if (plan === 'YEARLY') return price
  if (plan === 'MONTHLY') return price * 12
  if (plan === 'YEARLY_PER_PATIENT') return price * patientCount
}

export const calculateProductsDueForRenewelInNMonths = (stack, months) => {
  const productManagementDetails = stack?.StackProductManagements?.items

  return productManagementDetails?.filter(productManagementDetail => {
    if (!productManagementDetail.contractEndDate) {
      return false
    }

    const productContractEndDate = new Date(productManagementDetail.contractEndDate)
    const currentDate = new Date()
    const nMonthsLater = new Date()
    nMonthsLater.setMonth(currentDate.getMonth() + months)

    return productContractEndDate >= currentDate && productContractEndDate <= nMonthsLater
  })
}

export const calculateComplianceFieldCompletion = (stack, field) => {
  const productManagementDetails = stack?.StackProductManagements?.items

  let completed = 0
  let total = 0

  productManagementDetails?.forEach(productManagementDetail => {
    if (productManagementDetail[field] === 'Completed') completed++
    if (
      productManagementDetail[field] === 'Completed' ||
      productManagementDetail[field] === 'Not completed'
    )
      total++
  })

  return { completed, total }
}

export const getCategoryOptions = stacks => {
  const categories = []

  stacks?.forEach(stack => {
    stack?.SoftwareProducts?.items?.forEach(product => {
      const productCategory = product?.softwareProduct?.productCategory
      if (productCategory && !categories.find(category => category.id === productCategory?.id)) {
        categories.push({
          id: productCategory?.id,
          name: productCategory?.name,
        })
      }
    })
  })

  return categories.sort((productCategoryA, productCategoryB) => {
    if (productCategoryA.name > productCategoryB.name) return 1
    if (productCategoryB.name > productCategoryA.name) return -1
    return 0
  })
}

export const getTagOptions = stacks => {
  const tags = []

  stacks?.forEach(stack => {
    stack?.SoftwareProducts?.items?.forEach(product => {
      const productTags = product?.softwareProduct?.productTags?.items

      if (productTags.length) {
        productTags.forEach(({ productTag }) => {
          if (!tags.find(tag => tag.id === productTag?.id)) {
            tags.push({
              id: productTag?.id,
              name: productTag?.name,
            })
          }
        })
      }
    })
  })

  return tags.sort((productTagA, productTagB) => {
    if (productTagA.name > productTagB.name) return 1
    if (productTagB.name > productTagA.name) return -1
    return 0
  })
}

export const getPatientCount = stack => {
  if (!stack?.organisation?.organisationStats?.phePopulationSummary) return

  const phePopulationSummary = JSON.parse(
    JSON.parse(stack.organisation.organisationStats.phePopulationSummary)
  )
  const patientCount = phePopulationSummary?.ListSize?.Val
  return patientCount
}

export const getMostUsedProducts = stacks => {
  const softwareUsageCounts = {}
  stacks?.forEach(stack => {
    stack?.SoftwareProducts?.items?.forEach(item => {
      const softwareId = item.softwareProductId
      const productManagementDetail = stack?.StackProductManagements?.items?.find(
        productManagementItem =>
          productManagementItem.productManagementDetailsSoftwareProductId === softwareId
      )

      let productCost = 0
      if (productManagementDetail)
        productCost =
          calculatePlanCost(
            productManagementDetail.price,
            productManagementDetail.pricingPlan,
            getPatientCount(stack)
          ) || 0

      softwareUsageCounts[softwareId] = {
        name: item?.softwareProduct?.name,
        count: (softwareUsageCounts[softwareId]?.count || 0) + 1,
        categoryId: item?.softwareProduct?.productcategoryID,
        totalCost: (softwareUsageCounts[softwareId]?.totalCost || 0) + productCost,
      }
    })
  })

  let softwareCountsArray = Object.values(softwareUsageCounts)

  // Sort software products by usage count
  if (softwareCountsArray.length > 1) {
    softwareCountsArray = softwareCountsArray.sort((a, b) => b.count - a.count)
  }
  return softwareCountsArray
}
