export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const getTeamDetails = team => {
  return team?.map(teamMember => {
    let [id, role, ...email] = teamMember.split(':')
    return { id, role, email: email.join() }
  })
}

export const sortTeamMembersByRole = team => {
  if (!team) return
  const teamWithDetails = getTeamDetails(team)

  let owner
  let administators = []
  let readAccess = []

  teamWithDetails.forEach(teamMember => {
    if (teamMember.role === 'owner') owner = teamMember
    else if (teamMember.role === 'administrator') administators.push(teamMember)
    else if (teamMember.role === 'readAccess') readAccess.push(teamMember)
  })

  return { owner, administators, readAccess }
}

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const replaceTags = (text, find, replace) => {
  return text
    .split(find)
    .flatMap(item => [item, replace])
    .slice(0, -1)
}

export const percentage = (value, total) => {
  return (value / total) * 100
}

export const toTitleCase = str => {
  return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
}

export const calculateDaysBetweenDates = (start, end) => {
  if (!start || !end) return 'Invalid dates'

  const startDate = new Date(start)
  const endDate = new Date(end)

  const differenceInMs = endDate - startDate

  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))

  return differenceInDays
}

export const conditionalTableRowFormatting = index => {
  return index % 2 === 0 ? '#f1f5f9' : 'none'
}
