import { saveAs } from 'file-saver'
import { createReport } from '../lib/docx-templates/browser.js'
import templateDocx from '../documents/bordercross-master-template.docx'

export const generateClinicalSafetyDocx = async data => {
  const response = await fetch(templateDocx)
  const templateBuffer = await response.arrayBuffer()

  const report = await createReport({
    template: templateBuffer,
    data,
    cmdDelimiter: ['+++', '+++'],
  })

  const blob = new Blob([report], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  })
  saveAs(blob, 'DCB0160-Download.docx')
}
