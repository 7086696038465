import React from 'react'
import { toTitleCase } from '../../../../utilities/general'
import { formatICBName, titleCaseNHS } from '../../../../utilities/ods'
import { getCQCRatingText } from '../../../../utilities/phe'

const IntroductionOutput = ({ survey, product, stack, orgStats }) => {
  let population, populationSummary
  try {
    population = JSON.parse(orgStats?.phePopulation)
    populationSummary = JSON.parse(orgStats?.phePopulationSummary)
  } catch (e) {}

  const pcnName = stack?.organisation?.pcnName
  const icbName = stack?.organisation?.icbName

  return (
    <>
      <div>
        <p>
          This document outlines the implementation of the <strong>{product.name}</strong> within{' '}
          <strong>{stack.name}</strong>
          {pcnName && icbName ? (
            <span>
              {' '}
              part of the <strong>{titleCaseNHS(pcnName)}</strong> and{' '}
              <strong>{formatICBName(icbName)} ICS</strong>
            </span>
          ) : (
            ''
          )}
          . The purpose of this implementation is to ensure that the system is deployed, utilised,
          modified, or decommissioned in a manner that prioritises patient safety, enhances clinical
          workflows, and aligns with organisational and regulatory standards.
        </p>
        <p>
          <strong>{stack.name}</strong>, rated "{getCQCRatingText(population?.CQCRatingID)}" by the
          Care Quality Commission, serves a registered patient population of{' '}
          {populationSummary?.ListSize?.Val?.toLocaleString('en-GB')}. The practice's population has
          an average life expectancy of {population?.AdHocValues?.LifeExpectancyMale?.ValF} years
          for males and {population?.AdHocValues?.LifeExpectancyFemale?.ValF} years for females,
          with a deprivation decile of {population?.GpDeprivationDecile}, reflecting the
          socio-economic challenges of the area. In the most recent Quality and Outcomes Framework
          (QOF) assessment, the practice achieved {Math.round(population?.AdHocValues?.Qof?.Count)}/
          {population?.AdHocValues?.Qof?.Denom} points. Additionally,{' '}
          {Math.round(population?.AdHocValues?.Recommend?.Val)}% of patients report a positive
          experience with the practice.
        </p>
      </div>
      <h3>Scope</h3>
      <p>
        This Clinical Safety Case Report pertains to {stack.name} implementing {product.name} in
        it's current form as of {new Date(survey?.closedDate).toLocaleDateString()}, as evidenced by
        the supplier's DCB0129 documentation.
      </p>
    </>
  )
}

export default IntroductionOutput
