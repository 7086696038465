import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import IcbSelector from '../../IcbSelector'
import Button from '../../Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import useProducts from '../../../hooks/useProducts'
import ProductListItem from './IcsProductItem'
import useIcbs from '../../../hooks/useIcbs'

const ClinicalSafetyPage = ({ cognitoUser, stack }) => {
  const [icbProducts, setIcbProducts] = useState()
  const [loadingIcbProducts, setLoadingIcbProducts] = useState(false)
  const [selectedIcbId, setSelectedIcbId] = useState()
  const { products, loadingProducts } = useProducts()
  const { icbs, loadingIcbs } = useIcbs()
  const { stackId } = useParams()

  useEffect(() => {
    const getIcbProducts = async () => {
      setLoadingIcbProducts(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/productSurvey/list/icbProducts/${selectedIcbId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const products = await response.body.json()
      setIcbProducts(products)
      setLoadingIcbProducts(false)
    }

    if (selectedIcbId) getIcbProducts()
  }, [selectedIcbId])

  useEffect(() => {
    if (!selectedIcbId && icbs?.length) {
      setSelectedIcbId(icbs[0]?.id)
    }
  }, [selectedIcbId, icbs])

  const loading = loadingIcbs || loadingIcbProducts || loadingProducts

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8 bg-slate-50">
      <div className="flex flex-col md:flex-row gap-4 justify-between align-start mt-10">
        <div className="flex-shrink-0">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-center">
            <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Products
            </h1>
            {/* <Button
              variant="solid"
              color="indigo"
              to={`/employee-insights/product-survey-setup?stackId=${stackId}`}
            >
              Create new survey
            </Button> */}
          </div>
        </div>
        <div>
          {icbs.length > 0 && (
            <IcbSelector
              defaultValue={selectedIcbId}
              icbs={icbs}
              hideLabel={true}
              onChange={icbId => setSelectedIcbId(icbId)}
            />
          )}
        </div>
      </div>

      {loading ? (
        <LoadBox />
      ) : (
        <div className="mt-8">
          <ul className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 auto-cols-min">
            {icbProducts?.map(productId => {
              const product = products.find(product => product.id === productId)

              return (
                <div>
                  <ProductListItem icbId={selectedIcbId} product={product} />
                </div>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ClinicalSafetyPage
