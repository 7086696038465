import React from 'react'
import { conditionalTableRowFormatting } from '../../../../utilities/general'

const SurveyResultsOutput = ({
  survey,
  product: selectedProduct,
  surveyQuestions: productQuestions,
}) => {
  const surveyCompletions = survey?.byProduct?.find(
    product => product.productId === selectedProduct.id
  )?.completions
  const tableCellStyles = {
    border: '1px solid #cbd5e1',
    padding: '5px',
  }

  return (
    <>
      <h3>Full Survey results</h3>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableCellStyles}>Question</th>
            <th style={tableCellStyles}>Strongly Disagree</th>
            <th style={tableCellStyles}>Disagree</th>
            <th style={tableCellStyles}>Neutral</th>
            <th style={tableCellStyles}>Agree</th>
            <th style={tableCellStyles}>Strongly Agree</th>
          </tr>
        </thead>

        <tbody>
          {productQuestions
            ?.filter(question => question.type === 'rating')
            .map((question, index) => {
              const answers = []
              surveyCompletions?.forEach(completion =>
                completion.answers?.map(answer => {
                  if (answer.questionId === question.id) answers.push(answer)
                })
              )
              const ratings = answers.map(answer => answer.rating)

              return (
                <tr style={{ background: conditionalTableRowFormatting(index) }}>
                  <td style={{ ...tableCellStyles, width: '300px' }}>
                    {question.question.replace('<<softwareName>>', selectedProduct.name)}
                    <br />
                  </td>
                  <td style={{ ...tableCellStyles, width: '150px' }}>
                    {ratings.filter(rating => rating === 1)?.length}
                    <br />
                  </td>
                  <td style={{ ...tableCellStyles, width: '150px' }}>
                    {ratings.filter(rating => rating === 2)?.length}
                    <br />
                  </td>
                  <td style={{ ...tableCellStyles, width: '150px' }}>
                    {ratings.filter(rating => rating === 3)?.length}
                    <br />
                  </td>
                  <td style={{ ...tableCellStyles, width: '150px' }}>
                    {ratings.filter(rating => rating === 4)?.length}
                    <br />
                  </td>
                  <td style={{ ...tableCellStyles, width: '150px' }}>
                    {ratings.filter(rating => rating === 5)?.length}
                    <br />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <br />
    </>
  )
}

export default SurveyResultsOutput
