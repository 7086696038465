import React from 'react'

const SurveyHazardLogOutput = ({ survey }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  return (
    <table style={{ borderCollapse: 'collapse' }}>
      {summary?.hazardLogOutput?.hazards.map((hazard, index) => {
        const initialRisk = summary?.hazardLogOutput?.initialRisks?.[index]
        const additionalControl = summary?.hazardLogOutput?.additionalControls?.[index]
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{new Date(survey?.closedDate).toLocaleDateString()}</td>
            <td>{hazard?.effect}</td>
            <td>{hazard?.hazard}</td>
            <td>{hazard?.harm}</td>
            <td>{hazard?.possibleCauses}</td>

            <td>{initialRisk?.hitDesignDescription}</td>
            <td>{initialRisk?.hitDesignEvidence}</td>
            <td>{initialRisk?.userTrainingDescription}</td>
            <td>{initialRisk?.userTrainingEvidence}</td>
            <td>{initialRisk?.businessProcessDescription}</td>
            <td>{initialRisk?.businessProcessEvidence}</td>
            <td>{initialRisk?.initialRiskSeverity}</td>
            <td>{initialRisk?.initialRiskLiklihood}</td>
            <td>{initialRisk?.initialRiskScore}</td>
            <td>{initialRisk?.initialRiskJustification}</td>

            <td></td>
            <td></td>
            <td>{additionalControl?.userTrainingDescription}</td>
            <td>{additionalControl?.userTrainingEvidence}</td>
            <td>{additionalControl?.businessProcessDescription}</td>
            <td>{additionalControl?.businessProcessEvidence}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )
      })}
    </table>
  )
}

export default SurveyHazardLogOutput
