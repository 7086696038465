import React from 'react'
import { conditionalTableRowFormatting } from '../../../../utilities/general'

const ExecutiveSummaryHazardLogRisks = ({ summary }) => {
  const hazardLogOutput = summary?.hazardLogOutput
  const tableCellStyles = {
    border: '1px solid #cbd5e1',
    padding: '5px',
  }

  return (
    <>
      <h3>DCB0160 Hazard Log Summary</h3>
      <p>
        The internal workforce engagement activities identified{' '}
        <strong>{hazardLogOutput?.hazards?.length} hazards</strong> during the assessment process.
        These hazards are summarised below, with full details available in the accompanying hazard
        log:
      </p>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableCellStyles}>Hazard Description</th>
            <th style={tableCellStyles}>Potential Impact</th>
            <th style={tableCellStyles}>Controls</th>
          </tr>
        </thead>
        <tbody>
          {hazardLogOutput?.hazards?.map((hazard, index) => {
            return (
              <tr key={index} style={{ background: conditionalTableRowFormatting(index) }}>
                <td style={tableCellStyles}>{hazard?.hazard}</td>
                <td style={tableCellStyles}>{hazard?.harm}</td>
                <td style={tableCellStyles}>
                  {hazardLogOutput?.initialRisks?.[index]?.businessProcessDescription}{' '}
                  {hazardLogOutput?.initialRisks?.[index]?.userTrainingDescription}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <p>
        The mitigation measures outlined aim to reduce risks to an acceptable or accepted level,
        ensuring compliance with DCB0160 requirements.
      </p>
    </>
  )
}

export default ExecutiveSummaryHazardLogRisks
