import { classNames } from '../../../utilities/general'
import { formatSurveyType } from '../../../utilities/surveys'

const SurveyTypeBadge = ({ type, size = 'sm' }) => {
  const badgeScheme = {
    'pre-implementation': {
      backgroundColour: 'bg-green-100',
      textColour: 'text-green-700',
      ring: 'ring-green-600/20',
    },
    'post-implementation': {
      backgroundColour: 'bg-yellow-100',
      textColour: 'text-yellow-700',
      ring: 'ring-yellow-600/20',
    },
  }

  return (
    <>
      <span
        className={classNames(
          'inline-flex items-center rounded-md font-medium ring-1 ring-inset',
          size === 'xs' && 'px-2 py-1 text-xs',
          size === 'sm' && 'px-2 py-1 text-sm',
          badgeScheme[type].backgroundColour,
          badgeScheme[type].textColour,
          badgeScheme[type].ring
        )}
      >
        {formatSurveyType(type)}
      </span>
    </>
  )
}

export default SurveyTypeBadge
