import React from 'react'

const SurveyHazardAnalysisOutput = ({ survey }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  return (
    <>
      {summary?.HAZID_Summary && (
        <div>
          <h3 className="text-lg font-semibold">HAZID (Hazard Identification)</h3>
          <p>
            HAZID is used to systematically identify potential hazards associated with the system
            and its use. It focuses on finding risks that could impact safety, accuracy, or
            security.{' '}
          </p>
          <ul>
            {summary.HAZID_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.SWIFT_Summary && (
        <div>
          <h3 className="text-lg font-semibold">SWIFT (Structured What-If Technique)</h3>
          <p>
            SWIFT is a brainstorming technique that uses “What-If” scenarios to identify potential
            failures and consequences, followed by preventive measures.{' '}
          </p>
          <ul>
            {summary.SWIFT_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.FFA_Summary && (
        <div>
          <h3 className="text-lg font-semibold">FFA (Functional Failure Analysis)</h3>
          <p>
            FFA identifies possible failure modes in system functions and their potential
            consequences. It helps in understanding how each failure could impact clinical outcomes.{' '}
          </p>
          <ul>
            {summary.FFA_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.HAZOP_Summary && (
        <div>
          <h3 className="text-lg font-semibold">HAZOP (Hazard and Operability Analysis)</h3>
          <p>
            HAZOP studies deviations in system processes to identify hazards caused by incorrect or
            incomplete operations.{' '}
          </p>
          <ul>
            {summary.HAZOP_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.SHARD_Summary && (
        <div>
          <h3 className="text-lg font-semibold">
            SHARD (Software Hazard Analysis and Resolution in Design)
          </h3>
          <p>
            SHARD focuses on identifying software-related hazards, particularly those affecting data
            processing and system design.{' '}
          </p>
          <ul>
            {summary.SHARD_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.PHA_Summary && (
        <div>
          <h3 className="text-lg font-semibold">PHA (Preliminary Hazard Analysis)</h3>
          <p>
            PHA is a high-level technique used early in the process to identify potential hazards
            and vulnerabilities before full implementation.{' '}
          </p>
          <ul>
            {summary.PHA_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}

      {summary?.FRAM_Summary && (
        <div>
          <h3 className="text-lg font-semibold">FRAM (Functional Resonance Analysis Method)</h3>
          <p>
            FRAM models interactions between human and technological components to identify how
            variability can impact system performance.
          </p>
          <ul>
            {summary.FRAM_Summary.map(summaryItem => (
              <li>{summaryItem}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default SurveyHazardAnalysisOutput
