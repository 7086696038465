import SummaryTableRow from './SummaryTableRow'

const SummaryTable = ({ icbProductStats }) => {
  return (
    <div className="border border-gray-300 rounded-md overflow-hidden shadow-md">
      <table
        id="table"
        className="min-w-full rounded-md overflow-hidden"
        data-cols-width="40,40,20,20,20,12,12,12"
      >
        <thead className="bg-indigo-100">
          <tr className="align-top" data-height="40">
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Organisation
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Surveys
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Completions
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              DCB0160 Documentation
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            ></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {icbProductStats.map(stack => (
            <SummaryTableRow key={stack.stackId} stack={stack} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SummaryTable
