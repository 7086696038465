import React from 'react'
import { toTitleCase } from '../../../../utilities/general'

const SurveyCommentsOutput = ({
  survey,
  product: selectedProduct,
  surveyQuestions: productQuestions,
}) => {
  const surveyCompletions = survey?.byProduct?.find(
    product => product.productId === selectedProduct.id
  )?.completions

  return (
    <>
      <h3 className="">Survey comments</h3>
      {productQuestions
        ?.filter(question =>
          surveyCompletions.find(completion =>
            completion.answers.find(answer => answer.comment && question.id === answer.questionId)
          )
        )
        .map(question => {
          const answers = []
          surveyCompletions.forEach(completion =>
            completion.answers?.map(answer => {
              if (answer.questionId === question.id)
                answers.push({
                  ...answer,
                  role: completion.role,
                })
            })
          )
          const comments = answers
            .filter(answer => answer.comment)
            .map(answer => {
              return {
                comment: answer.comment,
                role: answer.role,
              }
            })

          return (
            <>
              <h4>{question.question.replace('<<softwareName>>', selectedProduct.name)}</h4>
              {comments.map(({ comment, role }) => {
                return (
                  <p>
                    <strong className="font-semibold">{role} </strong>: "{comment}"
                  </p>
                )
              })}
              <br />
            </>
          )
        })}
      <br />
    </>
  )
}

export default SurveyCommentsOutput
